import React, { } from 'react'
import Header from '../header/Header'
import Menu from '../menu/Menu'
import Footer from '../footer/Footer'
import "../css/base.css"
import "../css/page.css"

function CompanyApp(props:PropsValue) {
  

  
  return(

      <div id="page" className="page company"> 
          <Header  />
          <Menu />
          <article id="contents">
          <section className="s-contentsin2 topcomment">
            <h1 className="stitle4">会社概要</h1>
          </section>

          <section className="contentsin box-contents">
            <div className="l">
              <table className="form-table address-table">
                <tr>
                  <th className="ls10">
                    社名
                  </th>
                  <td>
                    あさひ高速印刷株式会社
                  </td>
                </tr>
                <tr>
                  <th>
                    本社所在地
                  </th>
                  <td>
                    大阪市西区江戸堀2丁目1番13号
                  </td>
                </tr>
                <tr>
                  <th>
                    設立年月日
                  </th>
                  <td>
                    昭和39年（創業昭和25年）
                  </td>
                </tr>
                <tr>
                  <th className="ls10">
                    代表者
                  </th>
                  <td>
                    岡　達也
                  </td>
                </tr>
                <tr>
                  <th className="ls10">
                    決算期
                  </th>
                  <td>
                    9月
                  </td>
                </tr>
                <tr>
                  <th className="ls10">
                    役員
                  </th>
                  <td>
                    <dl>
                      <dt>代表取締役</dt>
                      <dd>岡　達也</dd>
                    </dl>
                    <dl>
                      <dt>取締役</dt>
                      <dd>片岡　由美子</dd>
                    </dl>
                    <dl>
                      <dt>取締役</dt>
                      <dd>岡　純子</dd>
                    </dl>
                    <dl>
                      <dt>監査役</dt>
                      <dd>田渕　喜久男</dd>
                    </dl>
                  </td>
                </tr>
                <tr>
                  <th className="ls10">
                    従業員
                  </th>
                  <td>
                    98名
                  </td>
                </tr>
                <tr>
                  <th>
                    適格請求書<br/>発行事業者<br/>登録番号
                  </th>
                  <td>
                    T6120001040936
                  </td>
                </tr>
              </table>
            </div>
            <div className="r">
              <img src="/images/company/img01.jpg" alt="" />
              <img src="/images/company/img02.png" alt="" />
            </div>
          </section>
          </article>
          <Footer />
        </div>
  

  )
}

export default CompanyApp
