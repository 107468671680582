import React, { useState } from 'react'
import Header from '../../header/Header'
import Menu from '../../menu/Menu'
import Footer from '../../footer/Footer'
import LoginForm from './LoginForm'

import "../../css/step.css"

function LoginApp(props:PropsValue) {
  const [message, setMessage] = useState(null)

  return(
      <div id="page" className="member login"> 
        <Header />
        <Menu />
        <article id="contents">
        <section className="topcomment">
          <h1>会員ログイン </h1>
          {message ? <section className="contentsin">
          <ul className="errorbox">
            {
              message
            }
          </ul>
        </section> : null
      }
        </section>
      
            <LoginForm messageHandler={(e) => setMessage(e) }/>
        </article>
        <Footer />
      </div>


  )
}

export default LoginApp

