import React, {  } from 'react'
import Header from '../header/Header'
import Menu from '../menu/Menu'
import Footer from '../footer/Footer'
import "../css/base.css"
import "../css/page.css"

function HowtoApp(props:PropsValue) {

  return(

        <div id="page" className="page method">
          <Header  />
          <Menu />
          <article id="contents">
            <section className="s-contentsin2 topcomment">
            <h1 className="stitle-line">入稿方法</h1>
            <p>データの入稿方法は、「宛名あり」、「宛名あり＋可変項目あり」、「宛名無し」の３通りの印刷タイプと、<br className="pcbr" />
            それぞれにZ折、W折の２つの折タイプがございます。<br />
            入稿されたいタイプにより準備物が異なりますので、ご確認の上ご準備ください。</p>
          </section>

          <section className="contents box-sec01">
            <section className="contentsin box-contents plan-info-box">
              <dl className="plan01">
                <dt>宛名あり印刷</dt>
                <dd>
                  表面に宛名を配置した圧着ハガキ印刷です。<br/>
                  宛名の位置は自由に調整いただけます。
                </dd>
              </dl>

              <dl className="plan02">
                <dt>宛名あり+可変項目印刷</dt>
                <dd>
                  表面に宛名を中面に可変項目を配置した圧着ハガキ	印刷です。<br/>
                  宛名、可変項目（最大3つ）の位置は自由に調整いただけます。
                </dd>
              </dl>

              <dl className="plan03">
                <dt>宛名なし印刷</dt>
                <dd>
                  表面に宛名を中面に可変項目を配置した圧着ハガキ印刷です。<br/>
                  宛名、可変項目（最大3つ）の位置は自由に調整いただけます。
                </dd>
              </dl>
            </section>

            <section className="contentsin box-content plan-preparations-box">
              <h2><span>■</span>必要な準備物</h2>
              <dl className="preparations-plan">
                <dt>
                  <span>宛名あり印刷</span>
                  <span>宛名あり+可変項目印刷</span>
                </dt>
                <dd>
                  <img src="/images/method/preparations-plan.png" alt="デザインの準備+CSVの準備" />
                </dd>
              </dl>
              <dl className="preparations-plan03">
                <dt><span>宛名なし印刷</span></dt>
                <dd>
                  <img src="/images/method/preparations-plan3.png" alt="デザインの準備" />
                </dd>
              </dl>
            </section>
          </section>

          <section className="contentsin box-contents box-sec02">

            <div className="box preparations-pdf" id="download-design">
              <h2><img src="/images/method/preparations-title01.png" alt="デザインの準備" /></h2>
              <p>印刷の背景となるデザインをご準備いただきます。<br/>
              弊社でAdobe Illustratorで作成した専用のテンプレートをご用意しておりますので、ダウンロードいただきIllustrator内に記載しております注意事項を必ずご確認の上、データを作成してください。<br/>
              テンプレートは、Z折とW折の2つがございますので、ご希望のテンプレートをお選びください。<br/>
              Illustratorで作成したデータは、必ず色設定をCMYKにしPDFに変換したデータをご準備ください。</p>

              <div className="boxin">
                <h3>■Adobe Illustratorの御用可能なバージョン<br/>
                <strong>Adobe Illustrator  Ver5.0〜CC</strong></h3>
                <ul>
                  <li className="l">
                    <h4>Z折のデザイン用テンプレート</h4>
                    <div className="temp-box temp-ai-box">
                      <a href="/downloaddata/template-z.ai">デザインテンプレート<br/>
                      （Adobe Illustrator）</a>
                    </div>
                  </li>
                  <li className="r">
                    <h4>W折のデザイン用テンプレート</h4>
                    <div className="temp-box temp-ai-box">
                      <a href="/downloaddata/template-w.ai">デザインテンプレート<br/>
                      （Adobe Illustrator）</a>
                    </div>
                  </li>
                </ul>

                <div className="preparations-link-box">
                  <div className="preparations-link-boxin">
                    <p>準備が出来たら</p>
                    <div className="button or">
                      <a href="/order/step1">
                        <span>入稿ステップへ</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>



            <div className="box preparations-csv" id="download-csv">
              <h2><img src="/images/method/preparations-title02.png" alt="CSVの準備" /></h2>
              <p>宛名用CSVをご準備いただきます。CSVに必要な宛名を記載しデータをご準備の上、印刷ステップにお進みください。</p>

              <h3>■可変項目を配置されたい方へ</h3>
              <ul>
                <li>可変項目は、最大3つまで配置いただけます。</li>
                <li>可変項目の中の1つをＱＲコードに変換いただけます。</li>
              </ul>

              <div className="boxin">
                <div className="boxin2">
                <h3><span>■</span>可変項目とは？※「宛名あり＋可変項目あり」をお選びの方用</h3>
                <p>お客様番号や請求金額など、宛名以外に配置できる自由に入力いただける欄を最大3つご用意しました。<br/>
                宛名と可変項目は、印刷可能範囲であればどこにでも配置いただけます。<br/>
                宛名以外に配置したいテキストがある場合は、CSVにデータを記載の上、入稿ステップで「宛名あり＋可変項目あり」をお選びください。</p>

                <div className="img-box">
                  <div className="l">
                    <img src="/images/method/csv_img01_1.png" alt="" />
                  </div>
                  <div className="r">
                    <img src="/images/method/csv_img01_2.png" alt="" />
                  </div>
                </div>
              </div>


              <div className="boxin2">
                <h3><span>■</span>可変項目をＱＲコードに変換可能</h3>
                <p>入稿ステップ内で、最大3つの可変項目の中から1項目のみＱＲコードに変換いただけます。<br/>
                顧客にあわせたアドレスの作成などにご利用いただけます。</p>

                <div className="img-box">
                  <div className="l">
                    <img src="/images/method/csv_img02_1.png" alt="" />
                  </div>
                  <div className="r">
                    <img src="/images/method/csv_img02_2.png" alt="" />
                  </div>
                </div>
              </div>
            </div>


              <div className="template-link-box">
                <p>宛名テンプレート</p>
                <div className="temp-box temp-xls-box">
                  <a href="/downloaddata/input-sheet.xlsm">宛名テンプレート<br/>（Microsoft Exel）</a>
                </div>
              </div>

              <div className="preparations-link-box">
                <div className="preparations-link-boxin">
                  <p>準備が出来たら</p>
                  <div className="button or">
                    <a href="/order/step1">
                      <span>入稿ステップへ</span>
                    </a>
                  </div>
                </div>
              </div>

            </div>

          </section>
          </article>
          <Footer />
        </div>


  )
}

export default HowtoApp
