import React ,{useEffect} from 'react'
import titleImg from "../images/header/title.png"
import titleSubImg from "../images/header/title-side.png"
import loginImg from "../images/common/login.png"
import mypageImg from "../images/common/mypage.png"
import logoutImg from "../images/common/head-logout.png"
import useAuthState from '../auth/useAuthState'
//import useSendingState from '../sending/useSendingState'
import "./header.css"
function Header(props:PropsValue) {
  
 // const [order_request, setOrderRequest] = useState(false)
  const {token, logout, auth} = useAuthState()  
  //const {orders,  getSending} = useSendingState()

  useEffect(() => {
    auth()

  

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  /*
  useEffect(() => {
    if(order_request){
      if(orders.length >= 3){
        alert("入稿データがすでに3つ保存されています。マイページからデータを1つ削除してから、新しい入稿を行ってください。")
      }else{
        //window.location.href ="/order/step1"
        window.open("/order/step1", '_blank')
      }
      setOrderRequest(false)
    }
 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[orders]); */

  const  gotoStep =() => {  
    //
    if(token) {
      window.open("/order/step1?type=new", '_blank')
    }else{
      window.location.href = "/login"
    }
  }   

  const  logoutHandler =() => {  
    // $('body').toggleClass('open');
    logout()
  }   

  const clickMenuHandler = () => {
    console.log(document.getElementsByTagName("body"))
    document.getElementsByTagName("body")[0].classList.toggle("open")
  }
  return(
    <header id="header">

            <section id="headerin">
                <h2 id="title">
                    <a href="/">
                        <img src={titleImg} alt="あさひ高速印刷オンラインショップDM圧着はがきDM印刷" />
                    </a>
                    <div className="head-mark">
                        <img src={titleSubImg} alt="あさひ高速印刷オンラインショップDM圧着はがきDM印刷" />
                    </div>
                </h2>

                <nav id="navi">
              
                    <div className="mypage">
                        {
                          token ? 
                           <a href="/order-history">
                                <img src={mypageImg} alt="マイページへ" />
                            </a>
                          :  <a href="/login">
                          <img src={loginImg} alt="ログイン" />
                      </a>

                        }
                          
                    </div> {
                      token ? <div class="logout" onClick={() => logoutHandler()}>
                      <img src={logoutImg} alt="ログアウト" />
                      </div> : null
                    }

                    <div className="step" onClick={() => gotoStep()}>
                        <div>
                            <span>印刷入稿<br />ステップへ</span>
                        </div>
                    </div>

                    <div id="naviin">
                        <div id="nav-toggle" onClick={clickMenuHandler}>
                            <div>
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <p className="pmenu">MENU</p>
                            <p className="pclose">CLOSE</p>
                        </div>
                        <div id="global-nav" className="menu">
                           
                                <ul>
                                  <li><a href="/">HOME</a></li>
                                  <li><a href="/login">ログイン</a></li>
                                </ul>
                               
                                    <ul>
                                    <li><a href="/order">入稿の流れ</a></li>
                                    <li><a href="/howto">入稿方法（データ作成方法）</a></li>
                                    <li><a href="/delivery">料金・発送・お支払いについて</a></li>
                                    <li><a href="/faq">Q&A</a></li>
                                    <li><a href="/company">会社概要</a></li>
                                    <li><a href="/contact">お問合せ</a></li>
                                    </ul>
                             
                   
                       
                      </div>
                      <div id="nav-bg"></div>
                    </div>
                </nav>
            </section>

        </header>
  )
}

export default Header


/**
 @guest
                        <div className="mypage">
                            <a href="{{ route('login') }}">
                                <img src="../../images/common/login.png" alt="ログイン">
                            </a>
                        </div>        
                    @endguest
                    
                    @auth
                        <div className="mypage">
                            
                        </div>                        
                    @endauth
 */